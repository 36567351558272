import {
  WishlistActionSet,
  WishlistActionType,
  WishlistContextType,
  WishlistStateType,
} from "lib/types/wishlist";
import { createContext, useCallback, useContext, useEffect, useMemo, useReducer } from "react";
import { useAuthContext } from "./AuthProvider";
import { useMerchantContext } from "./MerchantProvider";
import useSWR, { mutate } from "swr";
import { fetcher, staticOptions } from "lib/core/apiClient";
import { parseWishlistData } from "lib/utils/wishlist";

const Context = createContext<WishlistContextType>({ state: {}, actions: {} } as WishlistContextType);

function reducer(state: WishlistStateType, action: WishlistActionType): WishlistStateType {
  switch (action.type) {
    case WishlistActionSet.SET_WISHLISTED_PRODUCTS: {
      return action.payload;
    }
  }
  return state;
}

const WishlistInitialState: WishlistStateType = {
  count: 0,
  wishlistedItems: [],
};

export const WishlistProvider: React.FC<React.ReactNode> = ({ children }) => {
  const {
    state: { isAuthenticated },
  } = useAuthContext();
  const {
    state: { merchant },
  } = useMerchantContext();

  const [reducerState, dispatch] = useReducer(reducer, WishlistInitialState);
  const { data: wishlistResponse, isValidating } = useSWR(
    isAuthenticated && merchant?.merchantId
      ? `/santa/v1/wishlists?merchant_id=${merchant?.merchantId}`
      : null,
    fetcher,
    staticOptions,
  );

  useEffect(() => {
    if (isValidating || !Boolean(wishlistResponse)) return
    const parsedWishlist = parseWishlistData(wishlistResponse)
    setWishlist(parsedWishlist)
  }, [wishlistResponse, isValidating])

  const setWishlist = useCallback((wishlist: WishlistStateType) => {
    dispatch({
      type: WishlistActionSet.SET_WISHLISTED_PRODUCTS,
      payload: wishlist
    })
  }, [])

  const refreshWishlist = useCallback(() => {
    if(isAuthenticated && merchant?.merchantId) {
      mutate(`/santa/v1/wishlists?merchant_id=${merchant?.merchantId}`)
    }
  }, [isAuthenticated, merchant])

  const isWishlisted = useCallback((variantId: string) => {
    const foundIndex = reducerState?.wishlistedItems.findIndex(item =>  item?.variantId === variantId)
    return foundIndex > -1 ? true : false
  }, [reducerState])

  const actions = useMemo(
    () => ({
      setWishlist,
      refreshWishlist,
      isWishlisted,
    }),
    [setWishlist, refreshWishlist, isWishlisted],
  );

  return (
    <Context.Provider
      value={{
        state: reducerState,
        actions,
      }}>
      {children}
    </Context.Provider>
  );
};

export function useWishlistContext() {
  if (!Boolean(Context)) throw new Error("useWishlistContext must be used within a WishlistProvider");
  const WishlistContext = useContext(Context);
  return WishlistContext as WishlistContextType;
}
