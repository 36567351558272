import { FloAnalyticsPayload } from "lib/types/analytics";
import { postAnalytics } from "lib/core/apiClient";
import { analyticsEvents } from "lib/utils/constants";

const STORAGE_KEY = "FLO_ANALYTICS_EVENTS";
const MAX_RETRY_LIMIT = 5;
const MAX_STORAGE_LIMIT = 100; // Maximum number of events in storage
const BATCH_SIZE = 5;
const INTERVAL = 5000;
let retryTimeout: NodeJS.Timeout;
let timer: NodeJS.Timeout | undefined = undefined;
let isRequestInProgress = false;

const CRITICAL_EVENTS = [
  analyticsEvents.FLO_AUTH_LOGIN_COMPLETED,
  analyticsEvents.FLO_PAYMENT_METHOD_SELECTED,
  analyticsEvents.FLO_PAYMENT_COMPLETED,
  analyticsEvents.FLO_CHECKOUT_CLICKED,

  // sso events
  analyticsEvents.FLO_SSO_PHONE_ADDED,
  analyticsEvents.FLO_SSO_OTP_VERIFIED,
  analyticsEvents.FLO_SSO_OTP_ENTERED,
  analyticsEvents.FLO_SSO_LOGIN_COMPLETED,
];

const sendBatchRequest = async () => {
  if (isRequestInProgress) return;

  let eventQueue: FloAnalyticsPayload[] = JSON.parse(localStorage.getItem(STORAGE_KEY) || "[]");
  let retryCount = 0;

  if (eventQueue.length === 0) return;

  try {
    isRequestInProgress = true;
    const eventsToSend = [...eventQueue];
    localStorage.setItem(STORAGE_KEY, JSON.stringify([]));

    const response = await postAnalytics(eventsToSend);

    if (response) {
      localStorage.removeItem(STORAGE_KEY);
      retryCount = 0; // Reset retry count on success
    } else {
      throw new Error("Failed to send batch analytics events");
    }
  } catch (error) {
    let currentQueue = JSON.parse(localStorage.getItem(STORAGE_KEY) || "[]");
    eventQueue = [...eventQueue, ...currentQueue];
    localStorage.setItem(STORAGE_KEY, JSON.stringify(eventQueue));

    retryCount++;
    if (retryCount <= MAX_RETRY_LIMIT) {
      retryTimeout = setTimeout(sendBatchRequest, 1000);
    } else {
      console.error("Max retry limit reached. Discarding events.");
      retryCount = 0;
      clearTimeout(retryTimeout);
    }
  } finally {
    isRequestInProgress = false;
  }
};

export const sendAnalyticsEvent = (event: FloAnalyticsPayload) => {
  let eventQueue: FloAnalyticsPayload[] = JSON.parse(localStorage.getItem(STORAGE_KEY) || "[]");
  eventQueue.push(event);
  if (eventQueue.length > MAX_STORAGE_LIMIT) {
    eventQueue.shift(); // Remove the oldest event if storage limit is exceeded
  }
  localStorage.setItem(STORAGE_KEY, JSON.stringify(eventQueue));

  if (!timer) {
    timer = setTimeout(() => {
      sendBatchRequest();
      clearTimeout(timer);
      timer = undefined;
    }, INTERVAL);
  }

  if (CRITICAL_EVENTS.includes(event.eventName) || eventQueue.length >= BATCH_SIZE) {
    clearTimeout(timer);
    sendBatchRequest();
    timer = undefined;
  }

  return Promise.resolve();
};

// Send any stored events on page load
window.addEventListener("load", () => {
  let eventQueue: FloAnalyticsPayload[] = JSON.parse(localStorage.getItem(STORAGE_KEY) || "[]");
  if (eventQueue.length > 0) {
    sendBatchRequest();
  }
});

document.addEventListener("visibilitychange", function logData() {
  let eventQueue: FloAnalyticsPayload[] = JSON.parse(localStorage.getItem(STORAGE_KEY) || "[]");
  if (document.visibilityState === "hidden") {
    if (eventQueue.length > 0) {
      sendBatchRequest();
    }
  }
});
