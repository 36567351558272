import Price from "components/common/Price";
import { useAuthContext } from "lib/contexts/AuthProvider";
import { useMerchantContext } from "lib/contexts/MerchantProvider";
import { useUserContext } from "lib/contexts/UserProvider";
import { removeItemFromWishlist } from "lib/core/apiMethods";
import { useLocale } from "lib/hooks/useLocale";
import { WishlistItemType } from "lib/types/wishlist";
import { analyticsEvents, eventTypes } from "lib/utils/constants";
import { useSearchParams } from "react-router-dom";
import {
  floorToTwoDecimals,
  insertBeforeLastOccurrence,
  publishPostMessage,
  truncateString,
} from "lib/utils/helpers";
import React from "react";
import { Heart, Plus, X } from "react-feather";
import { mutate } from "swr";
import { successToast } from "lib/utils/toasters";
import useSendAnalyticsEvent from "lib/hooks/useAnalytics";

interface WishListItemCardProps {
  index: number;
  item: WishlistItemType;
  parent: "UPSELL_STRIP" | "STORE_PAGE";
}

const WishListItemCard: React.FC<WishListItemCardProps> = ({ index, item, parent }) => {
  const { t } = useLocale();
  const {
    state: { user },
  } = useUserContext();
  const {
    state: { merchant },
  } = useMerchantContext();
  const [searchParams] = useSearchParams();

  const { sendAnalyticsEvent } = useSendAnalyticsEvent();

  const handleRemoveFromWishlist = async () => {
    const paramsMerchantId = searchParams.get("mid")?.toLowerCase();
    const paramsUserId = searchParams.get("uid")?.toLowerCase();
    const merchantId: string =
      paramsMerchantId && Boolean(paramsMerchantId)
        ? paramsMerchantId
        : merchant?.merchantId && Boolean(merchant?.merchantId)
        ? merchant?.merchantId
        : "";
    const userId: string =
      paramsUserId && Boolean(paramsUserId) ? paramsUserId : Boolean(user?.uid) ? user?.uid : "";
    try {
      const response = await removeItemFromWishlist(merchantId, item?.variantId);
      if (Boolean(response?.error)) {
        throw response?.error;
      }
      if (Boolean(response)) {
        successToast(t("removed_from_wishlist"), 3000);
        sendAnalyticsEvent({
          eventName: analyticsEvents.REMOVED_FROM_WISHLIST,
          eventType: "flo_action",
          metaData: {
            wishlistData: {
              variantId: item?.variantId,
              variantName: item?.variantName,
              productId: item?.productId,
              productName: item?.productName,
              parent: parent === "UPSELL_STRIP" ? "WISHLIST_STRIP" : "STORE_PAGE"
            }
          },
        });
      }
    } catch (e) {
      console.error(e);
    } finally {
      mutate(`/santa/v1/wishlists?merchant_id=${merchantId}`);
    }
  };

  const addItemToCart = () => {
    publishPostMessage(eventTypes.ADD_TO_CART, { item: { id: item?.variantId, quantity: 1 } });
    sendAnalyticsEvent({
      eventName: analyticsEvents.ADDED_FROM_WISHLIST,
      eventType: "flo_action",
      metaData: {
        wishlistData: {
          variantId: item?.variantId,
          variantName: item?.variantName,
          productId: item?.productId,
          productName: item?.productName,
          parent: parent === "UPSELL_STRIP" ? "WISHLIST_STRIP" : "STORE_PAGE"
        }
      },
    });
  }

  if (parent === "UPSELL_STRIP") {
    return (
      <li
        key={index}
        className={`relative flex max-h-36 min-w-[180px] max-w-[180px] snap-start flex-col gap-3 overflow-hidden rounded-xl border border-carbon-lighter p-2`}>
        <div
          className="flex flex-row gap-2 cursor-pointer"
          onClick={() => publishPostMessage(eventTypes.OPEN_WISHLIST_PRODUCT, { url: item?.link })}>
          <img
            src={insertBeforeLastOccurrence(item?.image, ".jpg", "_small")}
            alt="Cart Item"
            className={`h-[4.875rem] w-[4.875rem] min-w-[4.875rem] rounded-lg border border-gray-light object-cover`}
            draggable={false}
          />
          <div className="flex flex-col space-y-2">
            <p className="mt-1 w-[76px] text-wrap text-xs font-medium text-coal-dark">
              {truncateString(item?.productName, 32)}
            </p>
            <div className="flex flex-row space-x-0.5 text-xxs font-medium text-coal-light">
              <span>{item?.variantName}</span>
            </div>
          </div>
        </div>
        <div className="flex items-center justify-between">
          <div className="">
            <Price
              total={item?.currentPrice}
              compareAt={item?.originalPrice}
              orientation="vertical"
              customCSS="!flex-row !items-center !space-x-1 !text-sm"
              // {...(item?.originalPrice > item?.currentPrice
              //   ? {
              //       discountPercentage: floorToTwoDecimals(
              //         ((item?.originalPrice - item?.currentPrice) / item?.originalPrice) * 100,
              //       ),
              //     }
              //   : {})}
            />
          </div>
          <button
            onClick={addItemToCart}
            className="flex items-center justify-center space-x-1 rounded-lg border-2 border-yay-dark p-2 text-sm font-medium text-yay-dark">
            <Plus size={16} />
            <span>{t("add").toUpperCase()}</span>
          </button>
        </div>
        <div
          className={`absolute border-b top-0 left-[1px] border-r h-6 w-6 z-10 bg-white text-primary-dark border-primary-light p-1 rounded-tl-md rounded-br-lg cursor-pointer`}
          onClick={handleRemoveFromWishlist}>
          <Heart className={`h-4 w-4 fill-primary-dark`} strokeWidth={2.5} />
        </div>
      </li>
    );
  }

  return (
    <li
      key={index}
      className={`relative flex flex-col snap-start gap-3 max-h-[11rem] min-w-[19rem] md:max-w-[19rem] p-3 overflow-hidden rounded-xl border border-carbon-lighter`}>
      <div
        className="flex flex-row space-x-3 cursor-pointer"
        onClick={() => publishPostMessage(eventTypes.OPEN_WISHLIST_PRODUCT, { url: item?.link })}>
        <img
          src={insertBeforeLastOccurrence(item?.image, ".jpg", "_small")}
          alt="Cart Item"
          className={`h-[6rem] w-[6rem] min-w-[6rem] rounded-lg border border-gray-light object-cover`}
          draggable={false}
        />
        <div className="flex flex-col space-y-2">
          <p className="mt-1 w-[8rem] text-wrap text-sm font-medium text-coal-dark">
            {truncateString(item?.productName, 44)}
          </p>
          <div className="flex flex-row space-x-0.5 text-xs font-medium text-coal-light">
            <span>{item?.variantName}</span>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-between">
        <div className="pr-6">
          <Price
            total={item?.currentPrice}
            compareAt={item?.originalPrice}
            orientation="vertical"
            customCSS="!flex-row !items-center !space-x-1 !text-sm"
            {...(item?.originalPrice > item?.currentPrice
              ? {
                  discountPercentage: floorToTwoDecimals(
                    ((item?.originalPrice - item?.currentPrice) / item?.originalPrice) * 100,
                  ),
                }
              : {})}
          />
        </div>
        <button
          onClick={addItemToCart}
          className="flex items-center justify-center space-x-1 rounded-lg border-2 border-yay-dark p-2 text-sm font-medium text-yay-dark">
          <Plus size={16} />
          <span>{t("add").toUpperCase()}</span>
        </button>
      </div>
      <div className="flex flex-row justify-between">
        <button onClick={handleRemoveFromWishlist} className="absolute top-0 right-0 p-2 text-coal-light">
          <X className="w-5 h-5" />
        </button>
      </div>
    </li>
  );
};

export default WishListItemCard;
