import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter, useSearchParams } from "react-router-dom";
import "./assests/styles/index.css";
import "@shopflo/ui/styles.css";
import { AuthProvider } from "lib/contexts/AuthProvider";
import { UserProvider } from "lib/contexts/UserProvider";
import { CheckoutProvider } from "lib/contexts/CheckoutProvider";
import { MerchantProvider } from "lib/contexts/MerchantProvider";
import { addPolyfills } from "lib/core/polyfills";
import Skeleton from "components/common/loaders/Skeleton";
import { classNames, inIframe, isMobile } from "lib/utils/helpers";
import { PaymentProvider } from "lib/contexts/PaymentProvider";
import { LoaderProvider } from "lib/contexts/LoaderContext";
// import useSentry from "lib/hooks/useSentry";
// import { initialiseNRM } from "lib/third-party/newrelic";
// import * as Sentry from "@sentry/react";
import { WishlistProvider } from "lib/contexts/WishlistProvider";

addPolyfills();
// initialiseNRM();

const isMarketingPopup = window.location.search.includes("page=marketing-popup");

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter basename={import.meta.env.VITE_PUBLIC_URL}>
      <AppShell>
        <Suspense fallback={isMarketingPopup ? <></> : <Skeleton />}>
          <LoaderProvider>
            <AuthProvider>
              <UserProvider>
                <MerchantProvider>
                  <WishlistProvider>
                    <CheckoutProvider>
                      <PaymentProvider>
                        <App />
                      </PaymentProvider>
                    </CheckoutProvider>
                  </WishlistProvider>
                </MerchantProvider>
              </UserProvider>
            </AuthProvider>
          </LoaderProvider>
        </Suspense>
      </AppShell>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root"),
);

function AppShell({ children }: { children: React.ReactNode }) {
  const [searchParams] = useSearchParams();
  const isCartPage = searchParams.get("page") === "cart" || searchParams.get("page") === "CART";
  const isSsoLoginPage = searchParams.get("page") === "login-sso";
  const isMarketingPopup = searchParams.get("page") === "marketing-popup";
  const isWishlistPage = searchParams.get("page") === "wishlist";

  // useSentry();

  if (isMarketingPopup) {
    return (
      <div
        className={classNames(
          "overflow-hidden md:h-fit md:w-[25rem]",
          "md:shadow-[0px_0px_10px_0px_rgba(0,0,0,0.1)]",
        )}>
        {children}
      </div>
    );
  }

  if (isCartPage) {
    return (
      <div className="h-screen w-full overflow-hidden bg-gray-lightest md:h-[85vh] md:w-[25rem] md:rounded-l-[28px] md:rounded-r-none  md:shadow-[0px_0px_10px_0px_rgba(0,0,0,0.1)]">
        {children}
      </div>
    );
  }

  if (isSsoLoginPage) {
    return (
      <div
        className={`flex h-screen w-full overflow-hidden bg-gray-lighter ${
          inIframe() ? `justify-center` : `flex-col items-center justify-center`
        }`}>
        <div
          className={classNames(
            `h-[400px] w-[360px] border border-carbon-lighter bg-white md:h-[400px]`,
            isMobile() ? "rounded-xl" : "relative w-[360px] rounded-[20px] md:w-[360px]",
            inIframe() ? `!w-full !rounded-xl` : ``,
          )}>
          {children}
        </div>
      </div>
    );
  }

  if (isWishlistPage) {
    return <div className="h-full w-full overflow-hidden bg-transparent">{children}</div>;
  }

  return (
    <div
      className={classNames(
        "h-screen w-full overflow-hidden bg-gray-lightest md:h-[85vh] md:w-[25rem]",
        isMobile() ? "" : inIframe() ? `rounded-[28px]` : "md:rounded-[28px]",
        "md:shadow-[0px_0px_10px_0px_rgba(0,0,0,0.1)]",
      )}>
      {children}
    </div>
  );
}

window.addEventListener("vite:preloadError", () => {
  window.location.reload();
});
