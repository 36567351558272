import OTPInput from "components/auth/OTPInput";
import { DialogBody, GenericDialog } from "components/common/dialogs/GenericDialog";
import useForm from "lib/hooks/useForm";
import { useLocale } from "lib/hooks/useLocale";
import React, { useEffect, useState } from "react";
import { Edit } from "react-feather";
import "react-phone-number-input/style.css";
import { CartDialogType } from "lib/types/cart";
import TrustBadgeGray from "assests/images/trust-badge-gray.svg";
import { useSearchParams } from "react-router-dom";
import useSendAnalyticsEvent from "lib/hooks/useAnalytics";
import { analyticsEvents, eventTypes } from "lib/utils/constants";
import { resentOtp, verifyOtp } from "lib/utils/auth";
import { useAuthContext } from "lib/contexts/AuthProvider";
import { useUserContext } from "lib/contexts/UserProvider";
import { OTPChannel, UserLoginType } from "lib/types/user";
import { putRequest } from "lib/core/apiClient";
import { useCheckoutContext } from "lib/contexts/CheckoutProvider";
import { publishPostMessage, setPhoneNumberSpacing } from "lib/utils/helpers";
import OTPForm from "components/auth/forms/OTPForm";

interface CartOtpVerficationDialogProps {
  open: boolean;
  setDialog: (dialog: CartDialogType) => void;
  context?: "SSO" | "AUTH";
  showLoginConsent?: boolean;
}

const otpLength = 4;

const CartOtpVerficationDialog: React.FC<CartOtpVerficationDialogProps> = ({
  open,
  setDialog,
  context = "AUTH",
  showLoginConsent = true,
}) => {
  const { t } = useLocale();

  const [searchParams, setSearchParams] = useSearchParams();
  const phoneNumber = searchParams.get("phoneNumber");
  const contextId = searchParams.get("ctxId");

  const {
    state: { checkoutId, shopifySessionId, cartDialog },
  } = useCheckoutContext();

  const { sendAnalyticsEvent } = useSendAnalyticsEvent();

  const {
    actions: { login },
  } = useAuthContext();
  const {
    state: { user },
    actions: { setUserLoginType },
  } = useUserContext();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [counter, setCounter] = useState(30);
  const [counterExpired, setCounterExpired] = useState(false);
  const [resendOtpCounter, setResendOTPCounter] = useState(0);
  const [wrongOTPCounter, setWrongOTPCounter] = useState<number>(0);
  const {
    setValueOf,
    setErrors,
    handleSubmit,
    state: { values, errors },
  } = useForm({
    initialState: { otp: "" },
    validationSchema: {
      otp: {
        required: t("enter_your_otp"),
        numeric: t("otp_incorrect"),
        length: {
          limit: 4,
          message: t("otp_incorrect"),
        },
        formatters: ["NUMERIC"],
      },
    },
  });

  useEffect(() => {
    if (open) {
      setCounter(30);
      setCounterExpired(false);
    }
  }, [open]);

  useEffect(() => {
    if (counter === 0) setCounterExpired(true);
    const timer: any = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  //Validate OTP handler
  const validateOtp = async (otp: string) => {
    if (Boolean(otp) && otp?.length !== 4) return;
    setErrors({ otp: { status: false } });
    setIsLoading(true);

    try {
      // OTP entered event
      sendAnalyticsEvent({
        eventName: analyticsEvents.FLO_AUTH_OTP_ENTERED,
        eventType: "click",
        context: "cart",
      });
      if (!contextId) return;
      const result = await verifyOtp(contextId, otp);
      const authToken = result?.access_token;
      const authTokenExpiry = result?.access_token_expires_at;
      const refreshToken = result?.refresh_token;
      const refreshTokenExpiry = result?.refresh_token_expires_at;

      setIsLoading(false);
      login(authToken, refreshToken, authTokenExpiry, refreshTokenExpiry);
      await putRequest(`/checkout/v1/cart/${checkoutId}/checkout`, {});
      if (cartDialog !== "cartOtpVerification") {
        publishPostMessage(eventTypes.CART_TO_CHECKOUT, { checkoutId });
      }
      setDialog(null);
      setValueOf("otp", "");
      if (context === "SSO") {
        // if (onSuccess) onSuccess(authToken, refreshToken, authTokenExpiry, refreshTokenExpiry);
        return;
      }

      setUserLoginType(analyticsEvents.FLO_RETURN_USER_LOGIN as UserLoginType);

      sendAnalyticsEvent({
        eventName: analyticsEvents.FLO_RETURN_USER_LOGIN,
        eventType: "flo_action",
        metaData: {
          userData: {
            type: analyticsEvents.FLO_RETURN_USER_LOGIN,
          },
          authSource: "SHOPFLO",
        },
        context: "cart",
      });
    } catch (e) {
      setIsLoading(false);
      setErrors({ otp: { status: true, message: t("otp_incorrect") } });
      setWrongOTPCounter((oldValues) => oldValues + 1);
    }
  };

  const handleResendOtp = async (channel: OTPChannel) => {
    setIsLoading(true);
    sendAnalyticsEvent({
      eventName: analyticsEvents.FLO_AUTH_OTP_RESEND,
      eventType: "click",
    });
    setValueOf("otp", "");
    try {
      if (!contextId) return;
      await resentOtp(contextId, channel);
      setCounter(30);
      setCounterExpired(false);
      setResendOTPCounter((oldValues) => oldValues + 1);
    } catch (err) {
      console.error(err);
    }
    setErrors({ otp: { status: false } });
    setIsLoading(false);
  };

  useEffect(() => {
    if (values.otp && values.otp.length === 4) {
      validateOtp(values.otp);
    }
  }, [values.otp]);

  return (
    <GenericDialog
      isOpen={open}
      setIsOpen={() => {
        setDialog(null);
      }}
      translateAxis="y"
      customClass="overflow-scroll md:!top-auto md:absolute rounded-t-2xl max-h-[81vh]"
      dialogOverlay={true}
      closeOnOverlayClick={true}>
      <DialogBody className={`flex flex-col space-y-3 !p-3 !pb-14`}>
        <div className="flex flex-col space-y-1">
          <h2 className="inline-flex text-lg font-semibold text-coal-dark">Welcome back!</h2>
          <p className="text-sm font-normal text-coal-light">
            Use your saved addresses on Shopflo’s checkout network by entering the OTP
          </p>
        </div>
        {phoneNumber && (
          <div className="flex items-center text-sm text-coal-light">
            <span>{t("enter_otp_to_verify")}</span>
            <span className="pl-1 font-medium">{setPhoneNumberSpacing(phoneNumber)}</span>
            <button
              className="ml-2 rounded-lg bg-gray-lighter p-2"
              onClick={() => setDialog("authentication")}>
              <Edit className="h-4 w-4" />
            </button>
          </div>
        )}
        <>
          <OTPForm
            isLoading={Boolean(isLoading)}
            otpLength={4}
            otpValue={values.otp ?? ""}
            handleSubmit={handleSubmit(validateOtp)}
            handleChange={(code: string) => {
              setValueOf("otp", code);
              if (errors?.otp?.status) {
                setErrors({ otp: { status: false } });
              }
            }}
            validateOtp={validateOtp}
            handleResendOtp={handleResendOtp}
            error={errors?.otp?.status || false}
            errorMessage={t("otp_incorrect_login") || ""}
            autoFocus={true}
            otpCounter={counter}
            otpCounterExpired={counterExpired}
            emailValidation={false}
            showLoginConsent={true}
            context="cart"
          />
          <img src={TrustBadgeGray} className="h-4" alt={"shopflo-logo"} />
        </>
      </DialogBody>
    </GenericDialog>
  );
};

export default CartOtpVerficationDialog;
