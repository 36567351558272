export type WishlistType = {
  count: number;
  wishlistedItems: WishlistItemType[];
}

export type WishlistStateType = WishlistType

export enum WishlistActionSet {
  SET_WISHLISTED_PRODUCTS
}

export type WishlistActionType = 
  | {
    type: WishlistActionSet.SET_WISHLISTED_PRODUCTS
    payload: WishlistStateType
  }

export interface WishlistContextType {
  state: WishlistStateType
  actions: {
    setWishlist: (wishlist: WishlistStateType) => void
    refreshWishlist: () => void
    isWishlisted: (variantId: string) => boolean
  }
}

export type WishlistItemType = {
  variantId: string;
  variantName: string;
  sku: string;
  link: string;
  image: string;
  productId: string;
  productName: string;
  originalPrice: number;
  currentPrice: number;
}