import { useEffect, useState } from "react";
import { Confetti } from "@shopflo/ui";

const CartConfetti = ({
  total,
  mileStonesAmount,
  previousTotal,
  isCountBased,
}: {
  total: number;
  mileStonesAmount: number[];
  previousTotal: number;
  isCountBased: boolean;
}) => {
  const [showConfetti, setShowConfetti] = useState(false);
  const confettiValidation = !isCountBased
    ? mileStonesAmount.some((milestone) => total > milestone && previousTotal <= milestone)
    : mileStonesAmount.some((milestone) => total === milestone && previousTotal < total);

  useEffect(() => {
    if (confettiValidation) {
      setShowConfetti(true);
      setTimeout(() => {
        setShowConfetti(false);
      }, 4000);
    }
  }, [confettiValidation]);

  return (
    <>
      {showConfetti && (
        <div className="fixed h-[100vh] w-[400px] flex justify-center overflow-hidden pointer-events-none z-50">
          {Array.from({ length: 50 }).map((_, index) => (
            <div key={index} className="absolute top-[-50px]" style={{ left: `${index * 2}%` }}>
              <Confetti
                x={[-1, 1]}
                y={[0, 0.2]}
                delay={[500, 2000]}
                duration={2000}
                amount={10}
                fallDistance="50vh"
              />
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default CartConfetti;
