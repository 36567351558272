import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocale } from "lib/hooks/useLocale";
import { useUserContext } from "lib/contexts/UserProvider";
import { useMerchantContext } from "lib/contexts/MerchantProvider";
import { useCheckoutContext } from "lib/contexts/CheckoutProvider";
import { putRequest } from "lib/core/apiClient";
import { useAuthContext } from "lib/contexts/AuthProvider";

interface MarketingConsentProps {
  customClass?: string;
}

const MarketingConsent: React.FC<MarketingConsentProps> = ({ customClass }) => {
  const {
    state: { checkoutUIMetadata },
  } = useCheckoutContext();

  const { t } = useLocale();
  const {
    state: { marketingConsent },
    actions: { setMarketingConsent },
  } = useUserContext();
  const {
    state: { merchant },
  } = useMerchantContext();

  const {
    state: { isAuthenticated },
  } = useAuthContext();

  const isMarketingPopupSSOEnabled = useMemo(() => {
    return merchant?.isMarketingPopupEnabled;
  }, [merchant?.isMarketingPopupEnabled]);

  useEffect(() => {
    const updateMarketingConsent = async () => {
      if (marketingConsent === undefined) return;
      if (!Boolean(isAuthenticated)) return;
      try {
        await putRequest("/attributes/v1/account-attributes", {
          attributes: {
            marketing_consent: marketingConsent,
          },
        });
      } catch (err) {
        console.error(err);
      }
    };
    setMarketingConsent(marketingConsent ?? true);
    updateMarketingConsent();
  }, [marketingConsent, isAuthenticated, setMarketingConsent]);

  const renderSalesCommsContext = useCallback(() => {
    if (Boolean(merchant?.marketingContextText)) return merchant?.marketingContextText;
    switch (merchant?.salesCommsContext) {
      case 1: {
        return t("marketing_user_consent");
      }
      case 2: {
        return t("marketing_user_consent_v2");
      }
      case 3: {
        return t("marketing_user_consent_v3");
      }
      default: {
        return t("marketing_user_consent");
      }
    }
  }, [merchant?.salesCommsContext, t]);

  if (isMarketingPopupSSOEnabled || checkoutUIMetadata?.layout?.enableMarketingFlag) {
    return (
      <>
        <div
          className={`flex h-12 w-full flex-row items-center justify-center space-x-2 px-6 xxs:px-4 pt-7 ${customClass}`}>
          <input
            type="checkbox"
            id="marketing_user_consent"
            name="marketing_user_consent"
            className="h-[14px] w-[14px] cursor-pointer rounded-sm accent-coal-dark"
            checked={marketingConsent}
            onClick={() => {
              setMarketingConsent(!marketingConsent);
            }}
            onChange={() => {}}
          />
          <label
            htmlFor="marketing_user_consent"
            className="select-none text-xs font-normal text-coal-light cursor-pointer">
            {renderSalesCommsContext()}
          </label>
        </div>
      </>
    );
  } else {
    return <></>
  }
};

export default React.memo(MarketingConsent);
