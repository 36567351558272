import React, { useEffect, useMemo, useState } from "react";
import useSWR from "swr";
import { useCheckoutContext } from "lib/contexts/CheckoutProvider";
import { useLocale } from "lib/hooks/useLocale";
import { fetcher, publicFetcher, staticOptions } from "lib/core/apiClient";
import { useAuthContext } from "lib/contexts/AuthProvider";
import UpSellProductCard from "components/upsell/cards/UpSellProductCard";
import Carousel from "components/common/carousel/Carousel";
import * as Tab from "components/common/Tabs";
import { classNames } from "lib/utils/helpers";
import { parseUpSellData, parseUpsellIds, parseUpsellWithProduct } from "lib/utils/upsell";
import {
  UpsellPositionType,
  UpSellProductType,
  UpsellAndWishlistGroupsOnDiscountType,
} from "lib/types/upsell";
import UpSellVariantSelectorDialog from "components/upsell/dialogs/UpSellVariantsSelectorDialog";
import useSendAnalyticsEvent from "lib/hooks/useAnalytics";
import { analyticsEvents, analyticsTypes } from "lib/utils/constants";
import { useWishlistContext } from "lib/contexts/WishlistProvider";
import { useMerchantContext } from "lib/contexts/MerchantProvider";
import WishListItemCard from "components/wishlist/WishlistItemCard";
import { WishlistItemType } from "lib/types/wishlist";

interface UpsellSectionProps {
  parent?: UpsellPositionType;
}

const UpSellAndWishlistSection: React.FC<UpsellSectionProps> = React.memo(({ parent }) => {
  const { t } = useLocale();
  const {
    state: { isAuthenticated },
  } = useAuthContext();
  const {
    state: { checkoutId, checkoutUIMetadata, wishlistConfig, checkoutItems },
  } = useCheckoutContext();
  const { sendAnalyticsEvent } = useSendAnalyticsEvent();
  const {
    state: { count: wishlistedItemsCount, wishlistedItems },
  } = useWishlistContext();

  const [isVariantSelectorOpen, setIsVariantSelector] = useState<boolean>(false);
  const [selectedProduct, setSelectedProduct] = useState<UpSellProductType>();

  const { data: upsellResponse, isValidating } = useSWR(
    Boolean(isAuthenticated)
      ? `/checkout/v1/checkout/${checkoutId}/upsell`
      : `/v1/checkout/${checkoutId}/upsell`,
    Boolean(isAuthenticated) ? fetcher : publicFetcher,
    staticOptions,
  );

  const { discountedUpsells, nonDiscountedUpsells } = useMemo(() => {
    if (!Boolean(upsellResponse))
      return {
        discountedUpsells: [],
        nonDiscountedUpsells: [],
      };
    return parseUpSellData(upsellResponse);
  }, [upsellResponse]);

  const isWishlistEnabled = useMemo(() => {
    return !Boolean(wishlistConfig?.isEnabled)
      ? false
      : Boolean(
          Boolean(parent === "CART" && !Boolean(wishlistConfig?.config?.cart?.enabled)) ||
            Boolean(parent === "PAYMENT_PAGE" && !Boolean(wishlistConfig?.config?.checkout?.enabled)),
        )
      ? false
      : true;
  }, [parent, wishlistConfig]);

  const filteredWishlistedProducts = useMemo(() => {
    const checkoutItemsVariantIds = checkoutItems?.map((item) => item.variant_id);
    return wishlistedItems?.filter((item) => !checkoutItemsVariantIds.some((id) => id === item.variantId));
  }, [checkoutItems, wishlistedItems]);

  useEffect(() => {
    const loaderGroupIds = parseUpsellIds(upsellResponse);
    if (!Boolean(loaderGroupIds.length)) return;
    sendAnalyticsEvent({
      eventName: analyticsEvents.UPSELL_WIDGET_LOADED,
      eventType: "load",
      eventFor: [analyticsTypes.SF_ANALYTICS],
      context: parent === "CART" ? "cart" : "checkout",
      metaData: {
        upsellData: {
          position: parent === "CART" ? "cart" : "payment_page",
          groupData: parseUpsellWithProduct(upsellResponse),
        },
      },
    });
  }, [upsellResponse]);

  const openVariantSelector = (selectedProduct: UpSellProductType) => {
    setSelectedProduct(selectedProduct);
    setIsVariantSelector(true);
  };

  const closeVariantSelector = () => {
    setIsVariantSelector(false);
  };

  if (Boolean(isValidating))
    return (
      <div className="flex h-36 items-center justify-center rounded-2xl border border-gray-light">
        <div className="shimmer text-xs">{t("upsell_loading_text")}</div>
      </div>
    );

  if (!Boolean(discountedUpsells.length) && !Boolean(nonDiscountedUpsells.length)) return null;

  return (
    <div className="flex flex-col">
      <div className="flex w-full flex-col gap-3 rounded-2xl border-[2px] border-gray-light bg-white p-3 pb-0 pr-0">
        <Tab.Root
          defaultValue={
            discountedUpsells.length > 0
              ? UpsellAndWishlistGroupsOnDiscountType.DISCOUNTED
              : UpsellAndWishlistGroupsOnDiscountType.NON_DISCOUNTED
          }>
          <Tab.List className="spacex-2 mb-2 flex items-start space-x-4 text-sm">
            {discountedUpsells.length ? (
              <Tab.Trigger
                value={UpsellAndWishlistGroupsOnDiscountType.DISCOUNTED}
                renderTrigger={({ isSelected, onClick }) => {
                  return (
                    <button
                      onClick={onClick}
                      className={classNames(
                        "relative",
                        isSelected ? "font-semibold text-yay-dark" : "font-medium text-yay-dark",
                      )}>
                      {Boolean(checkoutUIMetadata?.upsellConfig?.discountedTitle)
                        ? checkoutUIMetadata?.upsellConfig?.discountedTitle
                        : t("best_offers")}
                      {Boolean(isSelected) && (
                        <span className="absolute -bottom-2 left-0 w-full border border-yay-dark"></span>
                      )}
                    </button>
                  );
                }}
              />
            ) : (
              <></>
            )}
            {nonDiscountedUpsells.length ? (
              <Tab.Trigger
                value={UpsellAndWishlistGroupsOnDiscountType.NON_DISCOUNTED}
                renderTrigger={({ isSelected, onClick }) => {
                  return (
                    <button
                      onClick={onClick}
                      className={classNames(
                        "relative",
                        isSelected ? "font-semibold text-coal-dark" : "font-medium text-coal-light",
                      )}>
                      {Boolean(checkoutUIMetadata?.upsellConfig?.title)
                        ? checkoutUIMetadata?.upsellConfig?.title
                        : t("you_might_also_like")}
                      {Boolean(isSelected) && (
                        <span className="absolute -bottom-2 left-0 w-full border border-coal-dark"></span>
                      )}
                    </button>
                  );
                }}
              />
            ) : (
              <></>
            )}
            {isWishlistEnabled && parent === 'CART' && wishlistedItemsCount > 0 && Boolean(filteredWishlistedProducts?.length) ? (
              <Tab.Trigger
                value={UpsellAndWishlistGroupsOnDiscountType.WISHLIST}
                renderTrigger={({ isSelected, onClick }) => {
                  return (
                    <button
                      onClick={onClick}
                      className={classNames(
                        "relative",
                        isSelected ? "font-semibold text-coal-dark" : "font-medium text-coal-light",
                      )}>
                      {parent === "CART"
                        ? Boolean(wishlistConfig?.config.cart.header)
                          ? wishlistConfig?.config.cart.header
                          : t("your_wishlist")
                        : Boolean(wishlistConfig?.config.checkout.header)
                        ? wishlistConfig?.config.checkout.header
                        : t("your_wishlist")}
                      {Boolean(isSelected) && (
                        <span className="absolute -bottom-2 left-0 w-full border border-coal-dark"></span>
                      )}
                    </button>
                  );
                }}
              />
            ) : (
              <></>
            )}
          </Tab.List>
          <Tab.Content value={UpsellAndWishlistGroupsOnDiscountType.NON_DISCOUNTED}>
            <div className="flex w-full flex-row items-center">
              <div className="flex w-full flex-col items-start justify-start space-y-2">
                <Carousel count={nonDiscountedUpsells?.length} customCSS="pr-3" shouldRenderDots={false}>
                  {nonDiscountedUpsells?.map((upSellProduct: UpSellProductType, index: number) => {
                    return (
                      <UpSellProductCard
                        isDiscounted={false}
                        product={upSellProduct}
                        openVariantSelector={openVariantSelector}
                        key={`upsell-product${index + 1}`}
                        position={parent ? parent : "PAYMENT_PAGE"}
                      />
                    );
                  })}
                </Carousel>
              </div>
            </div>
          </Tab.Content>
          <Tab.Content value={UpsellAndWishlistGroupsOnDiscountType.DISCOUNTED}>
            <div className="flex w-full flex-row items-center">
              <div className="flex w-full flex-col items-start justify-start space-y-2">
                <Carousel count={discountedUpsells?.length} customCSS="pr-3" shouldRenderDots={false}>
                  {discountedUpsells?.map((upSellProduct: UpSellProductType, index: number) => {
                    return (
                      <UpSellProductCard
                        isDiscounted
                        product={upSellProduct}
                        openVariantSelector={openVariantSelector}
                        key={`upsell-product${index + 1}`}
                        position={parent ? parent : "PAYMENT_PAGE"}
                      />
                    );
                  })}
                </Carousel>
              </div>
            </div>
          </Tab.Content>
          <Tab.Content value={UpsellAndWishlistGroupsOnDiscountType.WISHLIST}>
            <div className="flex w-full flex-row items-center">
              <div className="flex w-full flex-col items-start justify-start space-y-2">
                <Carousel
                  count={filteredWishlistedProducts?.length}
                  customCSS="pr-3"
                  shouldRenderDots={false}>
                  {filteredWishlistedProducts?.map((wishlistItem: WishlistItemType, index: number) => {
                    return (
                      <WishListItemCard
                        key={`item-${index + 1}`}
                        index={index}
                        item={wishlistItem}
                        parent="UPSELL_STRIP"
                      />
                    );
                  })}
                </Carousel>
              </div>
            </div>
          </Tab.Content>
        </Tab.Root>
      </div>
      <UpSellVariantSelectorDialog
        upsellProduct={selectedProduct}
        isVariantSelectorOpen={isVariantSelectorOpen}
        closeVariantSelector={closeVariantSelector}
        position={parent ? parent : "PAYMENT_PAGE"}
      />
    </div>
  );
});

export default UpSellAndWishlistSection;
