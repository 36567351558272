import { WishlistItemType, WishlistType } from "lib/types/wishlist";

export const parseWishlistData = (wishlistResponse: any): WishlistType => {
  if (!wishlistResponse || !Boolean(wishlistResponse)) {
    return {
      count: 0,
      wishlistedItems: [],
    };
  }

  const wishlistItems: WishlistItemType[] =
    Boolean(wishlistResponse?.results) && Boolean(wishlistResponse?.results?.length)
      ? wishlistResponse?.results?.map((item: any): WishlistItemType => {
          const defaultImage = item?.item?.product?.images?.find((image: any) => image?.is_primary)?.src;
          const variantImage: string =
            item?.item?.product?.images?.find((image: any) =>
              image?.linked_variant_ids?.includes(item?.item?.variant_id),
            )?.src ?? defaultImage;
          return {
            variantId: item?.item?.variant_id,
            variantName:
              Boolean(item?.item?.name) && item?.item?.name !== "Default Title"
                ? item?.item?.name
                : "",
            sku: item?.item?.sku ?? "",
            link: Boolean(item?.item?.product?.description)
              ? "/products/" + item?.item?.product?.description + "?variant=" + item?.item?.variant_id
              : "",
            image: variantImage,
            productId: item?.item?.product?.product_id ?? "",
            productName: item?.item?.product?.name ?? "",
            originalPrice: item?.item?.original_price ?? 0,
            currentPrice: item?.item?.current_price ?? 0,
          };
        })
      : [];

  return {
    count: wishlistResponse?.count,
    wishlistedItems: wishlistItems,
  };
};
