import Price from "components/common/Price";
import WishListItemCard from "components/wishlist/WishlistItemCard";
import { useAuthContext } from "lib/contexts/AuthProvider";
import { useMerchantContext } from "lib/contexts/MerchantProvider";
import { useUserContext } from "lib/contexts/UserProvider";
import { fetcher, getRequest, staticOptions } from "lib/core/apiClient";
import { useLocale } from "lib/hooks/useLocale";
import { WishlistItemType, WishlistType } from "lib/types/wishlist";
import { insertBeforeLastOccurrence, isMobile, truncateString } from "lib/utils/helpers";
import { parseWishlistData } from "lib/utils/wishlist";
import { useEffect, useMemo } from "react";
import { Bookmark, Plus } from "react-feather";
import { useSearchParams } from "react-router-dom";
import useSWR from "swr";

const Wishlist = () => {
  const {
    state: { isAuthenticated },
  } = useAuthContext();
  const { t } = useLocale();
  const {
    state: { merchant },
  } = useMerchantContext();
  const [searchParams] = useSearchParams();

  const paramsMerchantId = searchParams.get("mid")?.toLowerCase();
  const merchantId: string =
    paramsMerchantId && Boolean(paramsMerchantId)
      ? paramsMerchantId
      : merchant?.merchantId && Boolean(merchant?.merchantId)
      ? merchant?.merchantId
      : "";

  const { data: wishlistData, isValidating: wishlistLoading } = useSWR(
    isAuthenticated && Boolean(merchantId)
      ? `/santa/v1/wishlists?merchant_id=${merchantId}`
      : null,
    getRequest,
    staticOptions,
  );

  const wishList: WishlistType = useMemo(() => {
    if (!wishlistData || wishlistLoading) return { count: 0, wishlistedItems: [] };
    return parseWishlistData(wishlistData);
  }, [wishlistData, wishlistLoading]);

  return (
    <div className="flex flex-col h-full w-full items-start justify-start bg-white p-4">
      <div className="flex w-full justify-start p-4 border-b-2 border-b-gray-dark">
        <div className="flex h-6 items-center gap-2 font-medium text-coal-dark">
          <Bookmark className="h-5 w-5 md:h-6 md:w-6" />
          <div className="flex items-center gap-1">
            <span className="text-medium md:text-lg">{t("wishlist")}</span>
            {Boolean(wishList?.count) && (
              <>
                <span>·</span>
                <span className="text-sm md:text-base font-normal text-coal-light">
                  {t("n_items", { items: wishList?.count })}
                </span>
              </>
            )}
          </div>
        </div>
      </div>
      <div className={`flex flex-col w-full overflow-y-scroll pt-4 ${isMobile() ? "scrollbar-hide" : ""}`}>
        <ul className="flex flex-col gap-4 w-full md:flex-wrap md:flex-row">
          {wishList?.wishlistedItems?.map((item: WishlistItemType, index: number) => {
            return <WishListItemCard index={index} item={item} parent="STORE_PAGE" />;
          })}
        </ul>
      </div>
    </div>
  );
};

export default Wishlist;
