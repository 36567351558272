import React, { useCallback, useMemo, useState } from "react";
import { ChevronDown, Heart, Plus } from "react-feather";
import {
  floorToTwoDecimals,
  inIframe,
  insertBeforeLastOccurrence,
  isThirdPartyCookieEnabled,
  publishPostMessage,
  truncateString,
} from "lib/utils/helpers";
import { UpsellPositionType, UpSellProductType } from "lib/types/upsell";
import Price from "components/common/Price";
import { postRequest } from "lib/core/apiClient";
import { errorToast, infoToast, successToast } from "lib/utils/toasters";
import { ShippingHandlesType } from "lib/types/checkout";
import { mutate } from "swr";
import { useCheckoutContext } from "lib/contexts/CheckoutProvider";
import { useLocale } from "lib/hooks/useLocale";
import OverlaySpinner from "components/common/loaders/OverlaySpinner";
import blockImage from "assests/images/block-image.png";
import { useAuthContext } from "lib/contexts/AuthProvider";
import { useMerchantContext } from "lib/contexts/MerchantProvider";
import useSendAnalyticsEvent from "lib/hooks/useAnalytics";
import { analyticsEvents, analyticsTypes, eventTypes } from "lib/utils/constants";
import { getItems } from "lib/utils/checkout";
import { useWishlistContext } from "lib/contexts/WishlistProvider";
import { addItemToWishlist, removeItemFromWishlist } from "lib/core/apiMethods";

interface UpSellProductCardProps {
  isDiscounted: boolean;
  product: UpSellProductType;
  openVariantSelector: (selectedProduct: UpSellProductType) => void;
  keyIdentifier?: string;
  position: UpsellPositionType;
}

const UpSellProductCard: React.FC<UpSellProductCardProps> = ({
  isDiscounted,
  product,
  openVariantSelector,
  keyIdentifier,
  position,
}) => {
  const { t } = useLocale();
  const {
    state: { checkoutId, checkoutView, billing, initialCheckoutStep, checkoutValidations, wishlistConfig },
    actions: {
      setCheckoutView,
      setCheckoutModal,
      setShippingHandles,
      updateCheckoutBasedOnCheckoutResponse,
      setCartDialog,
      setCheckoutValidations,
    },
  } = useCheckoutContext();
  const {
    state: { isAuthenticated },
  } = useAuthContext();
  const {
    state: { merchant },
  } = useMerchantContext();
  const {
    state: { wishlistedItems },
    actions: { isWishlisted, refreshWishlist },
  } = useWishlistContext();
  const { sendAnalyticsEvent } = useSendAnalyticsEvent();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const availableVariants = useMemo(
    () => product?.variants?.filter((variant) => !Boolean(variant?.outOfStock)),
    [product],
  );

  const onAddBtnClick = useCallback(
    async (isVariant: boolean) => {
      if (isVariant) {
        openVariantSelector(product);
        return;
      }
      const payload = {
        items: [
          {
            variant_id: product?.variants?.[0]?.variantId,
            quantity: 1,
          },
        ],
      };

      try {
        setIsLoading(true);
        const url = Boolean(isAuthenticated)
          ? `/checkout/v1/checkout/${checkoutId}/upsell`
          : `/v1/checkout/${checkoutId}/upsell`;
        const response = await postRequest(
          url,
          payload,
          Boolean(isAuthenticated) ? "CHECKOUT" : "CHECKOUT_PUBLIC",
        );
        if (!response) {
          errorToast(t("unable_to_add"));
          return;
        }

        sendAnalyticsEvent({
          eventName: analyticsEvents.UPSELL_PRODUCT_ADDED,
          eventType: "click",
          eventFor: [analyticsTypes.SF_ANALYTICS, analyticsTypes.FACEBOOK_PIXEL],
          context: position === "CART" ? "cart" : "checkout",
          metaData: {
            upsellData: {
              position: position,
              productId: product.productId,
              UpSellSection: "generic",
              groupIds: product.upsellIds,
              upsoldItems: [
                {
                  productId: product.productId,
                  variantId: product.variants[0].variantId,
                  quantity: 1,
                  itemTotal: product.variants[0].currentPrice,
                },
              ],
            },
            checkoutItems: getItems(response?.items),
          },
        });

        sendAnalyticsEvent({
          eventName: analyticsEvents.FLO_ADDED_TO_CART_UI,
          eventType: "click",
          eventFor: [analyticsTypes.FACEBOOK_PIXEL],
          metaData: {
            checkoutItems: getItems(response?.items),
          },
        });

        successToast(t("upsell_product_added"));
        updateCheckoutBasedOnCheckoutResponse(response);

        const isAddressServiceable = response?.pricing?.serviceable ?? false;
        if (!Boolean(isAddressServiceable)) {
          if (Boolean(checkoutView === "PAYMENTS") && initialCheckoutStep !== "PAYMENTS") {
            setCheckoutView("ADDRESS_LIST");
          }
          if (Boolean(checkoutView === "PAYMENTS") && initialCheckoutStep === "PAYMENTS") {
            setCheckoutValidations({
              ...checkoutValidations,
              address: {
                isValid: false,
              },
            });
            setCheckoutModal("ADDRESS_LIST");
          }
          errorToast(t("serviceability_error"), 5000);
          return;
        }

        const shippingHandles = response?.metadata?.available_shipping_handles;
        const showShippingHandles = response?.metadata?.show_shipping_handle_selector ?? false;
        setShippingHandles(shippingHandles as ShippingHandlesType);
        if (
          Boolean(showShippingHandles) &&
          checkoutView === "PAYMENTS" &&
          initialCheckoutStep !== "PAYMENTS"
        ) {
          setCheckoutModal("SHIPPING_HANDLES");
        } else {
          mutate(`/checkout/v2/checkout/${checkoutId}/payments`);
          Boolean(isAuthenticated)
            ? mutate(`/checkout/v1/checkout/${checkoutId}/upsell`)
            : mutate(`/v1/checkout/${checkoutId}/upsell`);

          Boolean(isAuthenticated)
            ? mutate(`/checkout/v1/checkout/${checkoutId}/discounts`)
            : mutate(`/v1/checkout/${checkoutId}/discounts`);

          mutate(`UPI_INTENT`);
        }
      } catch (e: any) {
        const errorMsg = e?.response?.data?.error;
        errorToast(errorMsg ?? t("coupon_not_found"));
      } finally {
        setIsLoading(false);
      }
    },
    [product, billing, checkoutId, checkoutView, isAuthenticated],
  );

  const percentageDiscount = useMemo(() => {
    if (isDiscounted && product?.variants?.[0]?.discountedPrice) {
      return (
        ((product?.variants?.[0]?.currentPrice - product?.variants?.[0].discountedPrice) * 100) /
        product?.variants?.[0]?.currentPrice
      );
    }

    return 0;
    // return Boolean(product?.variants?.[0]?.originalPrice) && Boolean(merchant?.showOriginalPrice)
    //   ? ((product?.variants?.[0]?.originalPrice - product?.variants?.[0]?.currentPrice) * 100) /
    //       product?.variants?.[0]?.originalPrice
    //   : 0;
  }, [product.variants]);

  const getTotalPrice = useMemo(() => {
    if (isDiscounted && product?.variants?.[0]?.discountedPrice) {
      return product?.variants?.[0]?.discountedPrice;
    }
    return product?.variants?.[0]?.currentPrice;
  }, []);

  const getCompareAtPrice = useMemo(() => {
    return product?.variants?.[0]?.currentPrice;
  }, [product, merchant, isDiscounted]);

  const isWishlistEnabled = useMemo(() => {
    return !Boolean(wishlistConfig?.isEnabled)
      ? false
      : Boolean(
          Boolean(position === "CART" && !Boolean(wishlistConfig?.config?.cart?.enabled)) ||
            Boolean(position === "PAYMENT_PAGE" && !Boolean(wishlistConfig?.config?.checkout?.enabled)),
        )
      ? false
      : true;
  }, [position, merchant]);

  const isItemInWishlist = useMemo(() => {
    return isWishlisted(product?.variants?.[0]?.variantId);
  }, [wishlistedItems, product?.variants]);

  const handleAddToWishlist = async () => {
    if (!isAuthenticated && position === "CART") {
      if (inIframe() && !isThirdPartyCookieEnabled()) {
        let redirectUrl = new URL(document.location.href);
        let checkoutUrlSearchParams = new URLSearchParams(redirectUrl.search);
        checkoutUrlSearchParams.delete("page");
        checkoutUrlSearchParams.append("checkoutId", checkoutId);
        redirectUrl.search = checkoutUrlSearchParams.toString();
        publishPostMessage(eventTypes.PARENT_REDIRECT, {
          redirectUrl: redirectUrl.href,
          clearCart: false,
        });
        return;
      }
      setCartDialog("cartAuthentication");
      return;
    }
    if (!isAuthenticated) {
      infoToast(t("login_to_add_to_wishlist"), 3000);
      return;
    }
    try {
      const response = await addItemToWishlist(merchant?.merchantId ?? "", product?.variants?.[0]?.variantId);
      if (Boolean(response?.error)) {
        throw response?.error;
      }
      if (Boolean(response)) {
        successToast(t("added_to_wishlist"), 3000);
        sendAnalyticsEvent({
          eventName: analyticsEvents.ADDED_TO_WISHLIST,
          eventType: "flo_action",
          metaData: {
            wishlistData: {
              variantId: product?.variants?.[0]?.variantId,
              variantName: product?.variants?.[0]?.name,
              productId: product?.productId,
              productName: product?.name,
              parent: "UPSELL",
            },
          },
        });
      }
    } catch (e) {
      console.error(e);
      errorToast(t("error_adding_to_wishlist"), 3000);
    } finally {
      refreshWishlist();
    }
  };

  const handleRemoveFromWishlist = async () => {
    try {
      const response = await removeItemFromWishlist(
        merchant?.merchantId ?? "",
        product?.variants?.[0]?.variantId,
      );
      if (Boolean(response?.error)) {
        throw response?.error;
      }
      if (Boolean(response)) {
        successToast(t("removed_from_wishlist"), 3000);
        sendAnalyticsEvent({
          eventName: analyticsEvents.REMOVED_FROM_WISHLIST,
          eventType: "flo_action",
          metaData: {
            wishlistData: {
              variantId: product?.variants?.[0]?.variantId,
              variantName: product?.variants?.[0]?.name,
              productId: product?.productId,
              productName: product?.name,
              parent: "UPSELL",
            },
          },
        });
      }
    } catch (e) {
      console.error(e);
      errorToast(t("error_removing_from_wishlist"), 3000);
    } finally {
      refreshWishlist();
    }
  };

  return (
    <li
      key={keyIdentifier}
      className="relative flex max-h-36 min-w-[180px] max-w-[180px] snap-start flex-col gap-3 overflow-hidden rounded-xl border border-carbon-lighter p-2">
      <div className="flex flex-row gap-2">
        <img
          src={insertBeforeLastOccurrence(product?.variants?.[0]?.imageLink ?? blockImage, ".jpg", "_small")}
          alt="Cart Item"
          className={`h-[4.875rem] w-[4.875rem] min-w-[4.875rem] rounded-lg border border-gray-light object-cover`}
          draggable={false}
        />
        <div className="flex flex-col space-y-2">
          <p className="mt-1 w-[76px] text-wrap text-xs font-medium text-coal-dark">
            {truncateString(product?.name, 32)}
          </p>
          {Boolean(availableVariants?.length) && (
            <button
              onClick={() => {
                if (Boolean(product?.variants?.length > 1))
                  onAddBtnClick(Boolean(product?.variants?.length > 1));
              }}
              className="flex flex-row space-x-0.5 text-xxs font-medium text-coal-light">
              <span>{availableVariants?.length > 1 ? `${availableVariants.length} options` : ""}</span>
            </button>
          )}
        </div>
        {isWishlistEnabled && !isDiscounted && (
          <div
            className={`absolute border-b top-0 left-[1px] border-r h-6 w-6 z-10 bg-white ${
              isItemInWishlist ? "text-primary-dark border-primary-light" : "text-gray-dark border-gray-light"
            } p-1 rounded-tl-md rounded-br-lg cursor-pointer`}
            onClick={() => (isItemInWishlist ? handleRemoveFromWishlist() : handleAddToWishlist())}>
            <Heart className={`h-4 w-4 ${isItemInWishlist ? "fill-primary-dark" : ""}`} strokeWidth={2.5} />
          </div>
        )}
      </div>
      <div className="flex items-center justify-between">
        <div className="pr-6">
          <Price
            total={getTotalPrice}
            compareAt={getCompareAtPrice}
            orientation="vertical"
            customCSS="!flex-row !items-center !space-x-1 !text-sm"
          />
        </div>
        <button
          onClick={() => onAddBtnClick(Boolean(product?.variants?.length > 1))}
          className="flex items-center justify-center space-x-1 rounded-lg border-2 border-yay-dark p-2 text-sm font-medium text-yay-dark">
          <Plus size={16} />
          <span>{t("add").toUpperCase()}</span>
        </button>
      </div>
      {Math.round(percentageDiscount) > 0 ? (
        <div className="jusitfy-center absolute -left-[2px] -top-[2px] flex flex-col rounded-br-xl border border-carbon-lighter bg-yay-dark px-2 py-1 text-xxs font-semibold text-white">
          <span>{Math.round(percentageDiscount)}%</span>
          <span>OFF</span>
        </div>
      ) : (
        <></>
      )}
      {isLoading && <OverlaySpinner />}
    </li>
  );
};

export default UpSellProductCard;
